import React from 'react'

class ChatViewList extends React.Component{

	state = {}

	render(){
		return (
			<li className={`list-group-item ${this.props.chat.selected ? 'active' : ''}`} onClick={this.props.handlerChangeChat(this.props.chat.messageLast)}>
				{this.props.chat.name} {(this.props.chat.toRead > 0) ? `(${this.props.chat.toRead})` : ''}
			</li>
		)
	}

}


export default ChatViewList

