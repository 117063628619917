import React from 'react'

import { connect } from 'react-redux'

import { viewChange } from '../../../actions/appActions'

import './homeViewApp.css'

class HomeViewApp extends React.Component {
  onClick = () => {
    this.props.viewChange(this.props.view)
  }

  render() {
    return (
      <div className='home-app-container' onClick={this.onClick}>
        <div className='home-app-logo'>
          <i className={this.props.iconName}></i>
        </div>
        <div className='home-app-title'>{this.props.title}</div>
        <div className='home-app-disclaimer'>{this.props.disclaimer}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  viewChange: (payload) => dispatch(viewChange(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeViewApp)
