import React from 'react'

import { connect } from 'react-redux'

import PollsView from '../views/PollsView/PollsView'
import AdminView from '../views/adminView/adminView'
import EventsView from '../views/eventsView/eventsView'
import HomeView from '../views/homeView/homeView'
import LoginView from '../views/loginView/loginView'
import MenuRightView from '../views/menuRightView/menuRightView'
import MenuView from '../views/menuView/menuView'
import MusicView from '../views/musicView/musicView'
import ProfileView from '../views/profileView/profileView'
import QrCodeView from '../views/qrCodeView/qrCodeView'
import WrapperView from '../views/wrapperView/wrapperView'

import { useAppSelector } from '../../hooks/store'
import { useSocket } from '../../hooks/useSocket'
import ChatView from '../chat/chat-view/ChatView'

import './application.css'

const Application = () => {
  const view = useAppSelector((state) => state.appReducer.view)
  const spinner = useAppSelector((state) => state.appReducer.spinner)
  const socketIo = useSocket()

  const renderComponents = () => {
    switch (view) {
      case 'home':
        return renderView(HomeView)
      case 'chat': {
        return (
          <WrapperView>
            <ChatView ioEmit={socketIo?.ioEmit} />
          </WrapperView>
        )
      }
      case 'music':
        return renderView(MusicView)
      case 'events':
        return renderView(EventsView)
      case 'menu-right':
        return renderView(MenuRightView)
      case 'profile-view':
        return renderView(ProfileView)
      case 'qr-code':
        return renderView(QrCodeView)
      case 'menu':
        return renderView(MenuView)
      case 'polls':
        return renderView(PollsView)
      case 'admin':
      case 'admin-music':
      case 'admin-events':
      case 'admin-menu':
      case 'admin-orders':
      case 'admin-polls':
        return renderView(AdminView)
      default:
        return <LoginView />
    }
  }

  const renderSpinner = () => {
    if (!spinner) return null
    return (
      <div className='overload-contain overload-on-top spinner-background'>
        <div className='overload-contain-child'>
          <div className='overload-element text-center'>
            <div className='spinner-border' role='status' />
          </div>
        </div>
      </div>
    )
  }

  const renderView = (Component) => {
    return (
      <WrapperView>
        <Component />
      </WrapperView>
    )
  }
  return (
    <div>
      {renderComponents()}
      {renderSpinner()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state
})

export default connect(mapStateToProps)(Application)
