import avatarGenerator from 'animal-avatar-generator'

function userAttributes() {
  return {
    nickname: this.user?.nickname?.fullName,
    get avatar() {
      return avatarGenerator(this.nickname, {
        size: 50
      })
    },
    activeBar: this.bar?.barData?.activate,
    barId: this.bar?._id
  }
}
export { userAttributes }
