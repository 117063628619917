import React from 'react'
import QRCode from 'qrcode'

class QrCode extends React.Component{

	_uidd;

	constructor(props){
		super(props);

		this._uidd = this.randomInt();
	}

	async componentDidMount(){
		const element = document.querySelector(`#canvas-${this._uidd}`);
		const result = await QRCode.toCanvas(element, this.props.message, {
			width: 320
		});
		console.log({result});
	}

	randomInt(){
		return Math.floor(Math.random() * 10000000000);
	}

	render(){
		return <div>
			<canvas id={`canvas-${this._uidd}`}/>
		</div>
	}

}

export default QrCode

