import React from 'react'

import avatar from 'animal-avatar-generator'
import { connect } from 'react-redux'

import { setContext, viewChange } from '../../../actions/appActions'

import './profileView.css'

class ProfileView extends React.Component {
  statusInterval

  get userData() {
    return this.props.appReducer.user.user
  }

  get pictureData() {
    return this.userData.picture
  }

  get avatar() {
    return avatar(this.userData.nickname.fullName, { size: 50 })
  }

  get style() {
    return { backgroundImage: `url(${this.pictureData.url})` }
  }

  render() {
    if (this.userData === null) return

    return (
      <div className='card mt-3'>
        <div className='card-header'>Profile</div>
        <div className='card-img' style={this.style} />
        <div className='card-body'>
          <h5 className='card-title'>
            <span dangerouslySetInnerHTML={{ __html: this.avatar }} />
            {this.userData.name}
          </h5>
          <p className='card-body'>
            <strong>Nickname: </strong> {this.userData.nickname.fullName}
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  viewChange: (payload) => dispatch(viewChange(payload)),
  setContext: (payload) => dispatch(setContext(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView)
