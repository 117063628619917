import React from 'react'

import { connect } from 'react-redux'

import { spinner } from '../../../actions/appActions'
import ServiceManager from '../../../service/serviceManager'

//import './menuView.css';

class UploadFileView extends React.Component {
  state = {
    uuid: ''
  }

  generateUid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  }

  componentDidMount() {
    this.setState({ uuid: this.generateUid() })
  }

  handlerChange = async (event) => {
    this.props.spinner(true)
    event.preventDefault()
    event.stopPropagation()

    const [element] = document.getElementById(this.state.uuid).files

    const formData = new FormData()
    formData.append('element', element)

    const result = await ServiceManager.uploadFile(formData)

    if (this.props.onChange)
      this.props.onChange({
        target: {
          name: this.props.name,
          value: result.Location,
          type: 'text'
        }
      })

    this.props.spinner(false)
  }

  render() {
    return (
      <div className='upload-file'>
        <input
          id={this.state.uuid}
          name={this.props.fieldName}
          accept={this.props.accept}
          type='file'
          className='form-control'
          onChange={this.handlerChange}
        ></input>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  spinner: (payload) => dispatch(spinner(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileView)
