import ServiceManager from './../service/serviceManager';

export const viewChange = payload => {
	return {
		type: "change-view",
		payload
	};
}

export const viewChangeWithoutTracking = payload => {
	return {
		type: "change-view-without-traking",
		payload
	};
}

export const prevView = () => {
	return {
		type: "prev-view"
	};
}

export const setContext = payload => {
	return {
		type: "set-context",
		payload
	};
}

export const setProfile = payload => {
	return {
		type: "set-profile",
		payload
	};
}

export const spinner = payload => {
	return {
		type: "spinner",
		payload
	};
}

export function getQueue(){
	return async function getContextThunk(dispatch){
		const payload = await ServiceManager.getQueueCallout();
		dispatch({
			type: "get-spotify-queue",
			payload
		})
	}
}

// export const setContext = payload => {
// 	return {
// 		type: "set-context",
// 		payload
// 	};
// }

// export function setContext(username){
// 	return async function getContextThunk(dispatch){
// 		const response = await ServiceManager.syncData(username);
// 		const {data} = response.data;

// 		dispatch({
// 			type: "set-context",
// 			payload: data
// 		})
// 	}
// }

// export const setAdminContext = (tag, payload) => {
// 	return {
// 		type: "set-admin-context",
// 		tag,
// 		payload
// 	};
// }


export function setAdminContext(tag, payload) {
	return async function syncAdminDataThunk(dispatch, getState){
		console.log('WHAT')
		const username = '@smashh773';
		const {contextAdmin} = getState().appReducer;
		const data = {...contextAdmin, [tag]: payload};
		const response = await ServiceManager.sendAdminData(username, data);
		dispatch({
			type: 'set-admin-context',
			tag,
			payload
		})
	}
  }