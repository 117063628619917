function getRelativeTime(date) {
  const today = new Date()
  const diffInMS = today - date
  const seconds = Math.floor(diffInMS / 1000)

  const time = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

  if (seconds < 60) {
    return time.format(-seconds, 'second')
  }
  if (seconds < 3600) {
    const minutos = Math.floor(seconds / 60)
    return time.format(-minutos, 'minute')
  }
  if (seconds < 86400) {
    const horas = Math.floor(seconds / 3600)
    return time.format(-horas, 'hour')
  }
  const days = Math.floor(seconds / 86400)
  return time.format(-days, 'day')
}

export { getRelativeTime }
