import React from 'react'
import ChatList from '../chat-list/ChatList'

const Sidebar = ({
  onlineUsers = [],
  chats = [],
  handlerUserItemClick,
  handlerRoomItemClick
}) => {
  return (
    <nav className='chat-rooms d-none d-sm-block'>
      <main className='chat-rooms-list'>
        <section>
          <div className='chat-rooms-title'>Stanze</div>
          <ChatList
            items={chats}
            labelKey='name'
            labelActive='selected'
            handlerItemClick={handlerRoomItemClick}
            label={(chat) =>
              `${chat.name} ${chat.toRead > 0 ? `(${chat.toRead})` : ''} `
            }
          />
        </section>
        <section>
          <div className='chat-rooms-title'>Utenti</div>
          <ChatList
            items={onlineUsers}
            handlerItemClick={handlerUserItemClick}
            showImg
            showStatus
            img={{
              src: (item) => item?.user?.picture?.url,
              alt: (item) => item?.user?.nickname?.fullName
            }}
            label={(item) => item?.user?.nickname?.fullName}
            labelKey='_id'
          />
        </section>
      </main>
    </nav>
  )
}

export default Sidebar
