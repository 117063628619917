import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { loadFromLocalStorage, saveToLocalStorage } from './localStorageStore'
import rootReducer from './reducers'

// The store now has the ability to accept thunk functions in `dispatch`
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
// const store = createStore(rootReducer, loadFromLocalStorage(), composedEnhancer)

const store = createStore(rootReducer, loadFromLocalStorage(), composedEnhancer)
store.subscribe(() => saveToLocalStorage(store.getState()))
export default store
