import ServiceManager from './serviceManager'

export const API = () => ({
  findAll: async (barId, active = false) => {
    return fetch(
      `${ServiceManager.domain}/polls?barId=${barId}&active=${active}`
    ).then((res) => res.json())
  },
  findById: async (id) => {
    return fetch(`${ServiceManager.domain}/polls/${id}`).then((res) =>
      res.json()
    )
  },
  remove: async (id) => {
    return fetch(`${ServiceManager.domain}/polls/admin/${id}`, {
      credentials: 'include',
      method: 'DELETE'
    }).then((res) => res.json())
  },
  create: async (data) => {
    return fetch(`${ServiceManager.domain}/polls/admin`, {
      credentials: 'include',
      method: 'POST',
      body: data
    }).then((res) => res.json())
  },
  update: async (id, data) => {
    return fetch(`${ServiceManager.domain}/polls/vote/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(data)
    }).then((res) => res.json())
  }
})
