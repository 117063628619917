import { spinner } from 'actions/appActions'
import UploadFileView from 'components/views/uploadFileView/uploadFileView'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { useEffect, useRef, useState } from 'react'
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CloseButton,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { API } from 'service/polls.api'
import { userAttributes } from 'utils/model.util'

const IMAGE_TYPE = {
  URL: 'URL',
  FILE: 'FILE'
}

const TOAST_PROPERTIES = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light'
}

const ModalRecord = ({
  selectedElement: {
    title,
    image: imageValue,
    description,
    options: optionsValue = [],
    _id,
    votes = [],
    userVotes = {}
  },
  show,
  setShowRecordModal,
  onSubmit,
  barId,
  title: modalTitle
}) => {
  const isDisabled = votes.some((vote) => vote > 0)
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({})

  useEffect(() => {
    reset({
      title: title ?? '',
      description: description ?? ''
    })
    setOptions(optionsValue)
    setImage(imageValue)
  }, [_id])

  const newOptionRef = useRef()
  const [image, setImage] = useState(null)
  const [options, setOptions] = useState(optionsValue)
  const [imageType, setImageType] = useState(IMAGE_TYPE.URL)

  const handleAddOption = (event) => {
    event.preventDefault()
    if (!newOptionRef.current.value) return
    setOptions([...options, newOptionRef.current.value])
    newOptionRef.current.value = null
  }

  const handleClearOptions = () => {
    setOptions([])
  }
  const handleRemoveOption = (event) => {
    event.preventDefault()
    const { indexOption } = event.currentTarget.dataset
    setOptions(options.filter((option, index) => index !== Number(indexOption)))
  }

  const onSubmitHandler = async (obj) => {
    const data = new FormData()

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        data.append(key, obj[key])
      }
    }
    data.append('options', JSON.stringify(options))
    data.append('votes', JSON.stringify(new Array(options.length).fill(0)))
    data.append('imageType', imageType)
    data.append('barId', barId)
    if (!image) {
      toast.warn('Image is required!', TOAST_PROPERTIES)
      return
    }
    data.append('image', image)
    if (_id) data.append('id', _id)
    console.log({ data: { ...data, options, votes: [] } })
    setOptions([])
    setImageType(IMAGE_TYPE.URL)
    setImage(null)
    onSubmit(data)
  }

  const handlerImageChange = ({ target: { value } }) => {
    setImage(value)
  }

  return (
    <Modal
      show={show}
      onHide={() => setShowRecordModal(false)}
      backdrop='static'
      keyboard={false}
      size='lg'
    >
      <Form onSubmit={handleSubmit(onSubmitHandler)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='polls.title'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              {...register('title')}
              disabled={isDisabled}
              placeholder='Enter title'
            />
          </Form.Group>
          <Form.Group controlId='polls.image' className='mb-3'>
            <Form.Label>Image</Form.Label>
            <UploadFileView
              name='image'
              accept='image/png, image/jpeg'
              onChange={handlerImageChange}
            />
            {image && <Image src={image} rounded fluid className='mt-3 img' />}
          </Form.Group>

          <Form.Group className='mb-3' controlId='polls.description'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              {...register('description')}
              as='textarea'
              rows={3}
              disabled={isDisabled}
            />
          </Form.Group>

          <InputGroup className='mb-3'>
            <Form.Control
              placeholder='Add option'
              aria-label='Add option'
              aria-describedby='basic-addon2'
              ref={newOptionRef}
              disabled={isDisabled}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddOption(e)
                }
              }}
            />
            <Button
              variant='outline-secondary'
              id='add'
              onClick={handleAddOption}
              disabled={isDisabled}
            >
              Add
            </Button>
            <Button
              variant='outline-secondary'
              id='clear-options'
              onClick={handleClearOptions}
              disabled={isDisabled}
            >
              Clear
            </Button>
          </InputGroup>
          <ListGroup as='ol' numbered className='mb-3'>
            {options.map((option, index) => {
              return (
                <ListGroup.Item
                  as='li'
                  key={index}
                  className='d-flex justify-content-between align-items-start text-wrap cursor-pointer'
                >
                  <div className='ms-2 me-auto'>{option}</div>
                  {isDisabled ? (
                    <div className='d-flex align-items-center justify-content-center gap-1'>
                      <Badge pill bg='primary' text='light'>
                        {votes[index]}
                      </Badge>
                      <OverlayTrigger
                        placement='right'
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id='button-tooltip-2'>
                            {`Percentage based on a total of ${
                              Object.keys(userVotes).length
                            } voters`}
                          </Tooltip>
                        }
                      >
                        <Badge
                          pill
                          bg='primary'
                          text='light'
                          style={{ width: '80px', textAlign: 'right' }}
                        >{`${(
                          (votes[index] / Object.keys(userVotes).length) *
                          100
                        ).toFixed(2)} %`}</Badge>
                      </OverlayTrigger>
                    </div>
                  ) : (
                    <CloseButton
                      onClick={handleRemoveOption}
                      data-index-option={index}
                      className='float-end'
                    />
                  )}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowRecordModal(false)}>
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={isDisabled}>
            Create
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
const PollsView = () => {
  const [polls, setPolls] = useState([])
  const [showRecordModal, setShowRecordModal] = useState(false)
  const [selectedElement, setSelectedElement] = useState({})
  const [modalTitle, setModalTitle] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const elementId = useRef(null)

  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.appReducer.user)
  const { barId } = userAttributes.apply(user)

  const { create, findAll, remove } = API()
  useEffect(() => {
    getAll()
  }, [])

  const getAll = () => {
    dispatch(spinner(true))
    findAll(barId, true)
      .then((polls) => {
        setPolls(polls)
      })
      .finally(() => {
        dispatch(spinner(false))
      })
  }

  const handleEditClick = (poll) => {
    setModalTitle('Edit Poll')
    setSelectedElement(poll)
    setShowRecordModal(true)
  }

  const handleNewPoll = () => {
    setModalTitle('Create a Poll')
    setShowRecordModal(true)
    setSelectedElement({})
  }

  const onSubmit = async (data) => {
    dispatch(spinner(true))
    await create(data)
    toast.success('Successfully created!!', TOAST_PROPERTIES)
    setShowRecordModal(false)
    setSelectedElement({})
    getAll()
    dispatch(spinner(false))
  }

  const handleDeleteClick = (element) => {
    if (!element.active) {
      toast.error('Poll is not active!', TOAST_PROPERTIES)
      return
    }
    setShowDeleteModal(true)
    elementId.current = element._id
  }

  const handleCloseDeleteModal = () => {
    elementId.current = null
    setShowDeleteModal(false)
  }

  const handleDeleteAcceptModal = async () => {
    dispatch(spinner(true))
    await remove(elementId.current)
    toast.success('Successfully closed!!', TOAST_PROPERTIES)
    getAll()
    handleCloseDeleteModal()
    dispatch(spinner(false))
  }

  return (
    <>
      <Card className='mt-3'>
        <Card.Header>Polls</Card.Header>
        <Card.Body>
          <Button
            className='mb-3 float-end'
            variant='primary'
            onClick={handleNewPoll}
          >
            New Poll +
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Status</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {polls?.map((poll) => (
                <tr key={poll._id}>
                  <td>{poll.title}</td>
                  <td>{poll.date.substring(0, 10)}</td>
                  <td>{poll.active ? 'ACTIVE' : 'INACTIVE'}</td>
                  <td className='w-50'>{poll.description}</td>
                  <td className='d-flex justify-content-center'>
                    <ButtonGroup aria-label='Actions'>
                      <Button
                        variant='primary'
                        onClick={() => handleEditClick(poll)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant='danger'
                        onClick={() => handleDeleteClick(poll)}
                      >
                        Close
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <ToastContainer />
      <div
        className='modal show'
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Close Poll</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to close this poll?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleDeleteAcceptModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <ModalRecord
        show={showRecordModal}
        onSubmit={onSubmit}
        barId={barId}
        setShowRecordModal={setShowRecordModal}
        selectedElement={selectedElement}
        title={modalTitle}
      />
    </>
  )
}

export default PollsView
