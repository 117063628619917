import React from 'react'

import Moment from 'moment'
import { connect } from 'react-redux'
import { getQueue } from '../../../actions/appActions'

class QueueView extends React.Component {
  get tracks() {
    const sortedList = this.props.appReducer.queue.sort((a, b) =>
      a.timestamp >= b.timestamp ? -1 : 1
    )

    const unique = sortedList.reduce((result, current) => {
      if (result[current.id]) return result

      result[current.id] = current

      return result
    }, {})

    return Object.entries(unique).map(([, value]) => value)
  }

  componentDidMount() {
    this.props.getQueue()
  }

  render() {
    return (
      <div className='card mt-3'>
        <div className='card-header'>Queue</div>
        <div className='card-body'>
          <ul className='list-group'>{this.tracks.map(this.renderItem)}</ul>
        </div>
      </div>
    )
  }

  renderItem = (track) => {
    const classItem = ['list-group-item']

    if (this.props.appReducer.context?.status?.item?.id === track.id)
      classItem.push('active')
    return (
      <li
        className={classItem.join(' ')}
        key={`${track.id}-${track.timestamp}`}
      >
        <div className='row'>
          <div className='col-2'>
            <img
              alt='cover'
              height='50px'
              src={track.album.images.find((e) => e.height === 300)?.url}
            />
          </div>
          <div className='col-10'>
            <h5>{track.name}</h5>
            <h6>
              By {track.userData.nickname.fullName} (
              {Moment(track.timestamp).format('HH:mm:ss')})
            </h6>
          </div>
        </div>
      </li>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  getQueue: () => dispatch(getQueue())
})

export default connect(mapStateToProps, mapDispatchToProps)(QueueView)
