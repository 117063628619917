import React from 'react'

import { connect } from 'react-redux'

import { spinner } from '../../../actions/appActions'
import ServiceManager from '../../../service/serviceManager'

import './menuView.css'

class MenuView extends React.Component {
  state = {
    list: []
  }

  async componentDidMount() {
    this.props.spinner(true)
    const { result: list } = await ServiceManager.entityList('menus')
    this.setState({ list })
    this.props.spinner(false)
  }

  render() {
    return (
      <div className='menu-view'>
        <div className='card mt-3'>
          <div className='card-header'>Menu</div>
          <div className='card-body'>
            <div className='row justify-content-center'>
              {this.state.list.map(this.renderItem)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderItem = (item) => {
    return (
      <div className='col-12 col-md-6 col-lg-3 mt-3' key={item._id}>
        <div className='card h-100 card-shadow'>
          <img
            src={item.data.image}
            className='card-img-top'
            alt={item.data.name}
          />
          <div className='card-body'>
            <h5 className='card-title'>{item.data.name}</h5>
            <p className='card-text'>{item.data.description}</p>
          </div>
          <div className='card-footer'>
            <small className='text-muted'>
              <strong>Price: </strong> {item.data.price} €
            </small>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  spinner: (payload) => dispatch(spinner(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuView)
