import React from 'react'

import QrCode from "../../qrCode/qrCode";
import {connect} from "react-redux";

class BarSettings extends React.Component {

    get pathBarRegister() {
        return `${window.location.origin}?room=${this.props.appReducer.user.roomIp}`;
    }

    handlerClick(event) {
        event.currentTarget.setSelectionRange(0, event.currentTarget.value.length);
    }

    render() {
        return (
            <div className="card mt-3">
                <div className="card-header">
                    Associazione Bar
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mt-3">
                            <p className="card-text">Usa il seguente codice per associare il bar ad una utenza</p>
                        </div>
                        <div className="col-12 mt-3 text-center">
                            <QrCode message={this.pathBarRegister}/>
                        </div>
                        <div className="col-12 mt-3 text-center">
                            <input type="text" className="form-control" readOnly="readOnly" defaultValue={this.pathBarRegister} onClick={this.handlerClick}></input>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps)(BarSettings);