import React from 'react'

import { connect } from 'react-redux'

import { viewChangeWithoutTracking } from '../../../actions/appActions'

import BarPolls from '../../admin/BarPolls/BarPolls'
import BarConnect from '../../admin/barConnect/barConnect'
import BarEvents from '../../admin/barEvents/barEvents'
import BarInformation from '../../admin/barInformation/barInformation'
import BarMenu from '../../admin/barMenu/barMenu'
import BarSettings from '../../admin/barSettings/barSettings'
import BarSpotify from '../../admin/barSpotify/barSpotify'

import './adminView.css'

const tabs = [
  { view: 'admin', title: 'Bar' },
  { view: 'admin-music', title: 'Music' },
  { view: 'admin-events', title: 'Events' },
  { view: 'admin-menu', title: 'Menu' },
  { view: 'admin-orders', title: 'Orders' },
  { view: 'admin-polls', title: 'Polls' }
]

class AdminView extends React.Component {
  handlerClick = (item) => () => {
    debugger
    this.props.viewChangeWithoutTracking(item.view)
  }

  render() {
    return (
      <div className='row'>
        <div className='col-12'>
          <div className='d-none d-md-block'>
            <ul className='nav nav-tabs'>{tabs.map(this.renderTabItem)}</ul>
          </div>
          <div className='d-md-none'>
            <ul className='nav nav-pills flex-column flex-sm-row'>
              {tabs.map(this.renderTabItemMobile)}
            </ul>
          </div>
        </div>
        <div className='col-12'>{this.renderTavView()}</div>
      </div>
    )
  }

  renderTabItem = (item) => {
    const classList = ['nav-link']
    if (this.props.appReducer.view === item.view) classList.push('active')
    return (
      <li className='nav-item'>
        <button
          className={classList.join(' ')}
          onClick={this.handlerClick(item)}
        >
          {item.title}
        </button>
      </li>
    )
  }

  renderTabItemMobile = (item) => {
    const classList = ['flex-sm-fill', 'nav-link']
    if (this.props.appReducer.view === item.view) classList.push('active')
    return (
      <button className={classList.join(' ')} onClick={this.handlerClick(item)}>
        {item.title}
      </button>
    )
  }

  renderTavView = () => {
    debugger
    return (
      <div className='card'>
        <div className='card-body'>{this.renderTabViewDispatcher()}</div>
      </div>
    )
  }

  renderTabViewDispatcher = () => {
    debugger
    switch (this.props.appReducer.view) {
      case 'admin':
        return (
          <div>
            <BarInformation />
            <BarSettings />
            <BarConnect />
          </div>
        )
      case 'admin-music':
        return <BarSpotify />
      case 'admin-events':
        return <BarEvents />
      case 'admin-menu':
        return <BarMenu />
      case 'admin-polls':
        return <BarPolls />
      default:
        return null
    }
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  viewChangeWithoutTracking: (payload) =>
    dispatch(viewChangeWithoutTracking(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminView)
