import React from 'react'

import { Card, Col, Row } from 'react-bootstrap'

import HomeViewApp from '../homeViewApp/homeViewApp'

import { useAppSelector } from '../../../hooks/store'
import { userAttributes } from '../../../utils/model.util'
import './homeView.css'

const HomeView = () => {
  const user = useAppSelector((state) => state.appReducer.user)
  const { nickname, avatar, activeBar } = userAttributes.apply(user)

  const handlerClick = (event) => {
    event.currentTarget.setSelectionRange(0, event.currentTarget.value.length)
  }

  const renderActivateDisclaimer = () => {
    if (activeBar) return

    return (
      <div className='card mt-3'>
        <div className='card-header'>Bar not active</div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 text-center home-disclaimer'>
              <i className='bi bi-exclamation-triangle-fill'></i>
            </div>
            <div className='col-12 text-center'>
              Attualmente il bar non è attivo, contatta l'amministratore
            </div>
            <div className='col-12 mt-3'>
              <label className='form-label'>Room Id</label>
              <input
                type='text'
                className='form-control'
                readOnly='readOnly'
                defaultValue={user.roomIp}
                onClick={handlerClick}
              ></input>
            </div>
            <div className='col-12 mt-3'>
              <label className='form-label'>User Id</label>
              <input
                type='text'
                className='form-control'
                readOnly='readOnly'
                defaultValue={user.facebookId}
                onClick={handlerClick}
              ></input>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderCurrentProfile = () => {
    return (
      <div className='card home-margin-top'>
        <div className='card-header'>Sei connesso come</div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-2 col-md-1'>
              <div dangerouslySetInnerHTML={{ __html: avatar }} />
            </div>
            <div className='col-10 home-profile-nickname'>{nickname}</div>
          </div>
        </div>
      </div>
    )
  }

  const renderApps = () => {
    if (!activeBar) return

    return (
      <>
        <Card className='mt-3'>
          <Card.Header>Apps</Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12} md={6} lg={3} className='mt-3'>
                <HomeViewApp
                  title='Chat'
                  disclaimer='Conosci persone nuove'
                  iconName='bi bi-chat-dots-fill'
                  view='chat'
                />
              </Col>
              <Col xs={12} md={6} lg={3} className='mt-3'>
                <HomeViewApp
                  title='Music'
                  disclaimer='Scegli la musica'
                  iconName='bi bi-music-note-beamed'
                  view='music'
                />
              </Col>
              <Col xs={12} md={6} lg={3} className='mt-3'>
                <HomeViewApp
                  title='Events'
                  disclaimer='Guarda i nostri eventi'
                  iconName='bi bi-calendar-event-fill'
                  view='events'
                />
              </Col>
              <Col xs={12} md={6} lg={3} className='mt-3'>
                <HomeViewApp
                  title='Menu'
                  disclaimer='Guarda il menu'
                  iconName='bi bi-journal-text'
                  view='menu'
                />
              </Col>
              <Col xs={12} md={6} lg={3} className='mt-3'>
                <HomeViewApp
                  title='Ordina'
                  disclaimer='Fail il tuo ordine'
                  iconName='bi bi-cart4'
                  view='order'
                />
              </Col>
              <Col xs={12} md={6} lg={3} className='mt-3'>
                <HomeViewApp
                  title='QR Code'
                  disclaimer='Invita i tuoi amici'
                  iconName='bi bi-qr-code-scan'
                  view='qr-code'
                />
              </Col>
              <Col xs={12} md={6} lg={3} className='mt-3'>
                <HomeViewApp
                  title='Sondaggi'
                  disclaimer='Participa ai sondaggi'
                  iconName='bi bi-ui-checks'
                  view='polls'
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    )
  }

  const renderAppsAdmin = () => {
    if (!user.superAdmin && !user.userBar?.role === 'admin') return

    return (
      <div className='card mt-3'>
        <div className='card-header'>Apps Admin</div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-md-6 col-lg-3 mt-3'>
              <HomeViewApp
                title='Admin'
                disclaimer='Gestisci il bar'
                iconName='bi bi-gear-fill'
                view='admin'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='row'>
      <div className='col-12'>{renderCurrentProfile()}</div>
      <div className='col-12'>{renderActivateDisclaimer()}</div>
      <div className='col-12'>{renderApps()}</div>
      <div className='col-12'>{renderAppsAdmin()}</div>
    </div>
  )
}

export default HomeView
