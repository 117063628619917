import React from 'react'

import { connect } from 'react-redux'
import QrCode from '../../qrCode/qrCode'

import './qrCodeView.css'

class QrCodeView extends React.Component {
  get path() {
    return `${window.location.origin}?ref=${this.props.appReducer.user.facebookId}&refRoomId=${this.props.appReducer.user.roomIp}`
  }

  handlerClick(event) {
    event.currentTarget.setSelectionRange(0, event.currentTarget.value.length)
  }

  render() {
    return (
      <div className='card'>
        <div className='card-header'>QR Code</div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 mt-3'>
              Assicurarsi di essere connessi allo stesso WIFI per
              partecipare/entrare nella sessione del BAR
            </div>
            <div className='col-12 mt-3 text-center'>
              <QrCode message={this.path} />
            </div>
            <div className='col-12 mt-3 text-center'>
              <input
                type='text'
                className='form-control'
                readOnly
                defaultValue={this.path}
                onClick={this.handlerClick}
              ></input>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

export default connect(mapStateToProps)(QrCodeView)
