// const hashAlgorithm = (s) => `THIS${s}SECURE`

// convert object to string and store in localStorage
export function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state)
    localStorage.setItem('persistantState', serialisedState)
  } catch (e) {
    console.warn(e)
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
export function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem('persistantState')
    if (serialisedState === null) return undefined
    return JSON.parse(serialisedState)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

/* const JWT_SECRET = process.env.JWT_SCRET_REDUX_LOCALSTORAGE ?? 'codeStorm'
export const saveState = (state) => {
  const { chatReducer } = state
  try {
    const crypto = jwt.sign({ chatReducer }, JWT_SECRET)
    localStorage.setItem('state', crypto)
    localStorage.setItem(
      'stateToken',
      hashAlgorithm(JSON.stringify({ chatReducer }))
    )
  } catch (error) {
    console.error({ error })
  }
}

export const persistedState = jwt.verify(
  localStorage.getItem('state'),
  JWT_SECRET,
  (err, decoded) => {
    try {
      if (decoded) {
        if (!localStorage.getItem('state')) return
        const { chatReducer } = decoded
        if (
          hashAlgorithm(JSON.stringify({ chatReducer })).toString() !==
          localStorage.getItem('stateToken')?.toString()
        ) {
          localStorage.clear()
          return
        }
        return { chatReducer }
      }
      localStorage.clear()
    } catch (error) {
      console.error({ error })
    }
  }
) */
