import React from 'react'

import { connect } from 'react-redux'

import { setContext } from '../../../actions/appActions'
import ServiceManager from '../../../service/serviceManager'

import QueueView from '../queueView/queueView'
import SearchView from '../searchView/searchView'
import StatusView from '../statusView/statusView'

import './musicView.css'

class MusicView extends React.Component {
  current
  currentTimestamp
  statusInterval

  state = {
    spinner: true,
    connected: false,
    active: false
  }

  get timestamp() {
    return new Date().getTime()
  }

  get deltaByStart() {
    return this.timestamp - this.currentTimestamp + this.current.progress_ms
  }

  get timeByStart() {
    return new Date(this.deltaByStart).toISOString().substring(11, 19)
  }

  get deltaByEnd() {
    return this.current.item.duration_ms - this.deltaByStart
  }

  get timeByEnd() {
    return new Date(this.deltaByEnd).toISOString().substring(11, 19)
  }

  async componentDidMount() {
    await this.handlerStatus()
    this.setState({ spinner: false })
  }

  componentWillUnmount() {
    clearInterval(this.statusInterval)
  }

  handlerStatus = async () => {
    try {
      const response = await ServiceManager.statusCallout()
      const updateState = {
        connected: response.connected,
        active: response.active
      }

      if (!updateState.connected || !updateState.active)
        return this.poolingStatus(updateState)

      this.current = response
      this.currentTimestamp = new Date().getTime()

      this.statusTimeUpdate()

      clearInterval(this.statusInterval)
      this.statusInterval = setInterval(this.statusTimeUpdate, 1000)

      this.setState(updateState)
    } catch (err) {
      console.error(err)
    }
  }

  poolingStatus = async (updateState) => {
    this.setState(updateState)
    clearInterval(this.statusInterval)
    this.statusInterval = setInterval(this.handlerStatus, 10000)
  }

  statusTimeUpdate = async () => {
    if (this.deltaByEnd <= 0) {
      clearInterval(this.statusInterval)
      await this.wait(1000)
      return this.handlerStatus()
    }

    this.props.setContext({
      status: this.current,
      timeByStart: this.timeByStart,
      timeByEnd: this.timeByEnd
    })
  }

  wait(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  render() {
    if (this.state.spinner) return this.renderSpinner()
    if (!this.state.connected) return this.renderNotConnected()
    return (
      <div className='music-view'>
        <div className='row'>
          <div className='col-12'>
            {!this.state.active ? this.renderNotActive() : null}
          </div>
          <div className='col-12'>
            {this.state.active ? <SearchView /> : null}
          </div>
          <div className='col-12 mt-3'>
            {this.state.active ? <StatusView /> : null}
          </div>
          <div className='col-12'>
            <QueueView />
          </div>
        </div>
      </div>
    )
  }

  renderSpinner() {
    return (
      <div className='overload-contain overload-on-top'>
        <div className='overload-contain-child'>
          <div className='overload-element text-center'>
            <div className='spinner-border' role='status' />
          </div>
        </div>
      </div>
    )
  }

  renderNotConnected() {
    return (
      <div className='card'>
        <div className='card-header'>Spofify Not Connected</div>
        <div className='card-body'>
          <div className='row text-center'>
            <div className='col-12 music-disclaimer'>
              <i className='bi bi-exclamation-triangle-fill'></i>
            </div>
            <div className='col-12'>
              Attualmente il bar non è connesso a Spotify, contatta
              l'amministratore
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNotActive() {
    return (
      <div className='card'>
        <div className='card-header'>Music not playing</div>
        <div className='card-body'>
          <div className='row text-center'>
            <div className='col-12 music-information'>
              <i className='bi bi-info-circle-fill'></i>
            </div>
            <div className='col-12'>
              Attualmente il bar non sta riproducendo musica, contatta
              l'amministratore.
              <br />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  setContext: (payload) => dispatch(setContext(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MusicView)
