import React from 'react'
import Moment from 'moment';

import avatar from 'animal-avatar-generator'

import './chatViewItem.css';

class ChatViewItem extends React.Component{

	state = {}

	get time(){
		return Moment(this.props.item.timestamp).format('HH:mm:ss')
	}

	get avatar(){
		return avatar(this.props.item?.roomData?.user?.nickname?.fullName, { size: 50 });
	}

	get image(){
		return this.props.item?.roomData?.user?.picture?.url;
	}

	render(){
		if(this.props.item.myMessage) return this.renderMyMessage();
		return this.renderMessage();
	}

	renderMessage(){
		return (
			<div className="row mt-2" key={this.props.item.id}>
				<div className="col-12">
					<div className="chat-message-other">
						<div className="chat-message-box chat-message-box-other chat-message-other-box">
							<div className="row">
								<div className="chat-message-image" onClick={this.props.handlerChangeChat(this.props.item)}>
									<img src={this.image} alt={this.image}/>
								</div>
								<div className="col">
									<div className="chat-message chat-message-text">
										<h6>{this.props.item?.roomData?.user?.nickname?.fullName}</h6>
										{this.props.item?.message}
									</div>
								</div>
							</div>
							<div className="chat-message-time fw-light">{this.time}</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderMyMessage(){
		return (
			<div className="row mt-2" key={this.props.item.id}>
				<div className="col-12">
					<div className="chat-message-my">
						<div className="chat-message-box chat-message-my-box">
							<div className="row">
								<div className="col">
									<div className="chat-message chat-message-box-my chat-message-box-my chat-message-text">
										<h6>{this.props.item?.roomData?.user?.nickname?.fullName}</h6>
										{this.props.item?.message}
									</div>
								</div>
								<div className="chat-message-image">
									<img src={this.image} alt={this.image}/>
								</div>
							</div>
							<div className="chat-message-time fw-light">{this.time}</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ChatViewItem

