import React from 'react'

import { connect } from 'react-redux'

import { viewChange } from '../../../actions/appActions'
import ServiceManager from '../../../service/serviceManager'

import './menuRightView.css'

class MenuRightView extends React.Component {
  state = {
    spinner: false
  }

  handlerProfile = async () => {
    this.props.viewChange('profile-view')
  }

  handlerLogout = async () => {
    this.setState({ spinner: true })
    const result = await ServiceManager.logoutCallout()

    if (result.logout) this.props.viewChange('login')
    this.setState({ spinner: false })
  }

  render() {
    if (this.state.spinner) this.renderSpinner()
    return (
      <div>
        <ul className='list-group menu-right'>
          <li className='list-group-item' onClick={this.handlerProfile}>
            <i className='bi bi-person-fill'></i> Profilo
          </li>
          <li className='list-group-item' onClick={this.handlerLogout}>
            <i className='bi bi-box-arrow-left'></i> Esci
          </li>
        </ul>
        <blockquote
          className='secret-disclaimer blockquote mt-3'
          style={{ fontSize: '10px' }}
        >
          <p>
            * Questo progetto lo dedico alla persona che più nella mia vita ha
            fatto la differenza (CH)
          </p>
        </blockquote>
      </div>
    )
  }

  renderSpinner() {
    return (
      <div className='overload-contain overload-on-top spinner-background'>
        <div className='overload-contain-child'>
          <div className='overload-element text-center'>
            <div className='spinner-border' role='status' />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  viewChange: (payload) => dispatch(viewChange(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuRightView)
