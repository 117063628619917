import React from 'react'

import { connect } from 'react-redux'

import { getQueue } from '../../../actions/appActions'

import ServiceManager from '../../../service/serviceManager'

class SearchView extends React.Component {
  state = {
    keyword: '',
    results: [],
    spinner: false
  }

  handlerSearchChange = (event) => {
    const { value } = event.currentTarget
    this.setState({ keyword: value })
  }

  handlerSearchButton = async (event) => {
    event.preventDefault()
    this.setState({ spinner: true })
    const results = await ServiceManager.searchCallout(this.state.keyword)

    console.log({ results })
    this.setState({ results: results.tracks.items, spinner: false })
  }

  handlerSelect = (result) => async (event) => {
    console.log({ result })
    this.setState({ spinner: true })

    const resultAddToQueue = await ServiceManager.addToQueueCallout(
      result.uri,
      result.id
    )

    this.setState({ results: [], spinner: false })
    this.props.getQueue()
    console.log({ resultAddToQueue })
  }

  render() {
    if (this.state.spinner) return this.renderSpinner()

    return (
      <div className='card mt-3'>
        <div className='card-header'>Search Music</div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12'>
              <form>
                <div className='form-group'>
                  <label htmlFor='search-input'>Insert Keyword</label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      id='search-input'
                      onChange={this.handlerSearchChange}
                      placeholder='Enter Keyword'
                      type='search'
                      defaultValue={this.state.keyword}
                    />
                    <div className='input-group-append'>
                      <button
                        className='btn btn-primary'
                        onClick={this.handlerSearchButton}
                        type='submit'
                      >
                        Cerca
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-12'>
              <div className='row'>
                {this.state.results.map((result) => (
                  <div
                    className='col-12 col-md-6 col-lg-3 mt-3'
                    key={result.id}
                    onClick={this.handlerSelect(result)}
                  >
                    <div className='card'>
                      <img
                        className='card-img-top'
                        alt='cover'
                        src={
                          result.album.images.find((e) => e.height === 300)?.url
                        }
                      />
                      <div className='card-body'>
                        <h5 className='card-title'>{result.name}</h5>
                        <h6 className='card-subtitle mb-2 text-muted'>
                          {result.artists.map((e) => e.name).join(', ')}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSpinner() {
    return (
      <div className='overload-contain overload-on-top spinner-background'>
        <div className='overload-contain-child'>
          <div className='overload-element text-center'>
            <div className='spinner-border' role='status' />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  getQueue: () => dispatch(getQueue())
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchView)
