import React from 'react'

import { connect } from 'react-redux'

import { prevView, viewChange } from '../../../actions/appActions'

import './wrapperView.css'

class WrapperView extends React.Component {
  get style() {
    return {
      backgroundImage: `url(${this.props.appReducer?.user?.bar?.barData?.image})`
    }
  }

  handlerBack = () => {
    this.props.prevView()
  }

  handlerMenuRight = () => {
    this.props.viewChange('menu-right')
  }

  render() {
    return (
      <div className='wrapper'>
        {this.props.appReducer.view === 'home'
          ? this.renderBanner()
          : this.renderMenu()}
        <div className='container'>{this.props.children}</div>
      </div>
    )
  }

  renderBanner() {
    return (
      <div className='wrapper-banner' style={this.style}>
        <div className='wrapper-banner-blur'>
          <div className='wrapper-banner-container overload-contain'>
            <div className='overload-contain-child'>
              <div className='overload-element text-center'>
                <div className='wrapper-title'>
                  {this.props.appReducer?.user?.bar?.barData?.name || 'No Name'}
                </div>
                <div className='wrapper-disclaimer'>
                  {this.props.appReducer?.user?.bar?.barData?.disclaimer ||
                    'No Name'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='wrapper-menu-right' onClick={this.handlerMenuRight}>
          <i className='bi bi-list'></i>
        </div>
      </div>
    )
  }

  renderMenu() {
    return (
      <div className='wrapper-menu'>
        <div className='wrapper-menu-contain'>
          <div className='wrapper-menu-element'>
            <div className='row'>
              <div className='col-4' onClick={this.handlerBack}>
                <div className='wrapper-menu-back'>
                  <i className='bi bi-chevron-compact-left' /> Indietro
                </div>
              </div>
              <div className='col-4 text-center'>
                <div className='wrapper-menu-title'>
                  {this.props.appReducer.view}
                </div>
              </div>
              <div className='col-4'></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  viewChange: (payload) => dispatch(viewChange(payload)),
  prevView: () => dispatch(prevView())
})

export default connect(mapStateToProps, mapDispatchToProps)(WrapperView)
