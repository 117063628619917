import React from 'react'

import { connect } from 'react-redux'
import { setContext, viewChange } from '../../../actions/appActions'

class StatusView extends React.Component {
  statusInterval

  get contextData() {
    return this.props.appReducer.context
  }

  get statusData() {
    return this.contextData.status
  }

  get style() {
    return {
      backgroundImage: `url(${
        this.statusData.item.album.images.find((e) => e.height === 300)?.url
      })`
    }
  }

  render() {
    if (this.contextData === null) return

    return (
      <div className='card'>
        <div className='card-img' style={this.style} />
        <div className='card-body'>
          <h5 className='card-title'>{this.statusData.item.name}</h5>
          <h6 className='card-subtitle mb-2 text-muted'>
            {this.statusData.item.artists.map((e) => e.name).join(', ')}
          </h6>
          <p className='card-text'>
            {this.contextData.timeByStart} / {this.contextData.timeByEnd}
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  viewChange: (payload) => dispatch(viewChange(payload)),
  setContext: (payload) => dispatch(setContext(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusView)
