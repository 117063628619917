/* eslint-disable import/no-anonymous-default-export */
const initialState = {view: '', spinner: false, context: null, contextAdmin: {}, user: null, queue: [], viewTrack: []};
export default (state = initialState, action) => {
	let updateState = {};
    switch (action.type) {
        case "change-view-without-traking":
			updateState =  {
            	view: action.payload
        	}
			break;
        case "change-view":
			updateState =  {
            	view: action.payload,
				viewTrack: [...state.viewTrack, action.payload]
        	}
			break;
		case "prev-view":
			const viewTrack = [...state.viewTrack.slice(0, state.viewTrack.length - 1)];
			const view = viewTrack[viewTrack.length - 1] || 'login';

			updateState =  {
				view,
				viewTrack
			}
			break;
        case "set-context":
			updateState =  {
            	context: action.payload
        	};
			break;
        case "set-profile":
			updateState =  {
            	user: action.payload
        	};
			break;
		case "set-admin-context":
			updateState =  {
				contextAdmin: {...state.contextAdmin, [action.tag]: action.payload}
			};
			break;
		case "spinner":
			updateState =  {spinner: action.payload};
			break;
		case "get-spotify-queue":
			updateState =  {
				queue: action.payload
			};
			break;
        default:
			return state;
    }

	return {...state, ...updateState}
};

