export const clientDisconnect = (payload) => {
  return {
    type: 'client-disconnect',
    payload
  }
}

export const clientConnected = (payload) => {
  return {
    type: 'client-connected',
    payload
  }
}

export const messageAdd = (payload) => {
  return {
    type: 'message-add',
    payload
  }
}

export const roomSelect = (payload) => {
  return {
    type: 'room-select',
    payload
  }
}
