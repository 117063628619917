import { useMemo } from 'react'
import { io } from 'socket.io-client'
import {
  clientConnected,
  clientDisconnect,
  messageAdd
} from '../actions/chatActions'
import { useAppDispatch, useAppSelector } from './store'

export const useSocket = () => {
  const user = useAppSelector((state) => state.appReducer.user)

  const dispatch = useAppDispatch()

  const timestamp = () => new Date().getTime()
  const getChatAction = (action, data = {}, socketId) => {
    return {
      id: `${user?.user?.facebookId}-${timestamp()}`,
      roomData: user,
      timestamp: timestamp(),
      action,
      socketId,
      ...data
    }
  }

  const socketConnect = (socketInstance) => (event) => {
    const data = getChatAction('join', {
      roomId: 'lobby',
      socketId: socketInstance.id
    })
    socketInstance.emit('chat', data)
  }

  const handlerReceive = (data) => {
    console.log('++++handlerReceive++++', data)

    switch (data.action) {
      case 'disconnect':
        dispatch(clientDisconnect(data))
        break
      case 'connected':
        dispatch(clientConnected(data))
        break
      default: {
        console.log({ data })
        data.user = user
        dispatch(messageAdd(data))
      }
    }
  }

  return useMemo(() => {
    if (!user) return
    const socketConnection = io(
      process.env.REACT_APP_HOST_API || 'http://localhost:3000',
      {
        reconnectionDelay: 3600000,
        reconnectionDelayMax: 3600000,
        reconnectionAttempts: 99999
      }
    )

    socketConnection.on('chat', handlerReceive)
    socketConnection.on('connect', socketConnect(socketConnection))

    return {
      ioEmit: (event, [action, data]) => {
        const socketData = getChatAction(action, data, socketConnection.id)
        socketConnection.emit(event, socketData)
      }
    }
  }, [user])
}
