import React from 'react'

import {connect} from "react-redux";

class BarInformation extends React.Component {

    handlerClick(event) {
        event.currentTarget.setSelectionRange(0, event.currentTarget.value.length);
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    Bar Information
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label">Bar Id</label>
                            <input type="text" className="form-control" readOnly="readOnly" defaultValue={this.props.appReducer.user.roomIp} onClick={this.handlerClick}></input>
                        </div>
                        <div className="col-12 mt-3">
                            <label className="form-label">User Id</label>
                            <input type="text" className="form-control" readOnly="readOnly" defaultValue={this.props.appReducer.user.facebookId} onClick={this.handlerClick}></input>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps)(BarInformation);