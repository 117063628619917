import React, { useState } from 'react'

import ChatViewItem from '../chatViewItem/chatViewItem'
import ChatViewList from '../chatViewList/chatViewList'

import { roomSelect } from '../../../actions/chatActions'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import Sidebar from '../sidebar/Sidebar'
import './ChatView.css'

const ChatView = ({ ioEmit }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.appReducer.user)
  const messages = useAppSelector((state) => state.chatReducer.messages)
  const element = document.querySelector('#chat-space')
  if (element) {
    element.scrollTop = element.scrollHeight
  }

  const [state, setState] = useState({
    roomId: 'lobby',
    recipient: null,
    roomAnonymously: false,
    showRooms: false
  })

  const messagesRoom = () => {
    return messages[state.roomId] ?? []
  }

  const chatsOrder = () => {
    return Object.entries(messages)
      .map(([id, messages]) => {
        const selected = id === state.roomId
        const messagesToRead = messages
          .map((message) => {
            return {
              ...message,
              read: selected ? true : message.read
            }
          })
          .filter((e) => !e.read)

        const [messageLast] = [...messages].sort((a, b) =>
          a.timestamp >= b.timestamp ? -1 : 1
        )
        const name = id === 'lobby' ? id : null
        const nameToView =
          user.facebookId === messageLast.roomData.facebookId
            ? messageLast?.recipient?.roomData?.user?.nickname?.fullName
            : messageLast.roomData.user.nickname.fullName
        return {
          id,
          name: name || nameToView,
          messages,
          toRead: messagesToRead.length,
          messageLast,
          selected
        }
      })
      .sort((a, b) =>
        a.messageLast.timestamp >= b.messageLast.timestamp ? -1 : 1
      )
  }

  const onlineUsers = () => {
    return user?.usersOnline
      ?.filter(({ facebookId }) => facebookId !== user?.user?.facebookId)
      .map((item) => {
        const selected = state.roomId.includes(item.user.facebookId)
        return {
          ...item,
          selected
        }
      })
  }

  const handlerSubmit = (event) => {
    event.preventDefault()
    const message = event.target.message?.value
    if (!message) return

    if (state.roomId === 'lobby') {
      ioEmit('chat', [
        'chat',
        {
          message,
          roomId: state.roomId
        }
      ])
      event.target.reset()
      return
    }
    ioEmit('chat', [
      'chat-user',
      {
        message,
        roomId: state.roomId,
        recipient: state.recipient
      }
    ])
    event.target.reset()
  }

  const handlerSelectUser = (item) => (event) => {
    const roomId = [user?.user?.facebookId, item?.roomData?.user?.facebookId]
      .sort()
      .join('/')

    setState({
      ...state,
      roomAnonymously: true,
      recipient: item,
      roomId: roomId
    })

    dispatch(roomSelect(roomId))
  }

  const handlerUserItemClick = (item) => {
    const roomId = [user?.user?.facebookId, item.facebookId].sort().join('/')
    setState({
      ...state,
      roomAnonymously: true,
      recipient: { roomData: item },
      roomId: roomId
    })

    dispatch(roomSelect(roomId))
  }

  const handlerChangeChat = (item) => (event) => {
    setState({
      ...state,
      roomAnonymously: item.roomId !== 'lobby',
      recipient: item,
      roomId: item.roomId,
      showRooms: false
    })

    dispatch(roomSelect(item.roomId))
  }
  const handlerRoomItemClick = ({ messageLast }) => {
    setState({
      ...state,
      roomAnonymously: messageLast.roomId !== 'lobby',
      recipient: messageLast,
      roomId: messageLast.roomId,
      showRooms: false
    })

    dispatch(roomSelect(messageLast.roomId))
  }

  const handlerShowRooms = () => {
    setState({
      ...state,
      showRooms: !state.showRooms
    })
  }

  const renderSpace = () => {
    return (
      <div id='chat-space' className='chat-space'>
        <div className='chat-space-section'>
          {state.showRooms ? (
            <div className='chat-space-room-list'>
              {renderChatRoomSectionMobile()}
            </div>
          ) : null}
          <div className='chat-space-room-action'>
            <div
              className='chat-rooms-mobile-action d-sm-none'
              onClick={handlerShowRooms}
            >
              <div>
                <i className='bi bi-chevron-double-left'></i>
              </div>
            </div>
          </div>
          <div className='chat-space-list'>
            {messagesRoom()
              .filter((e) => e.message)
              .map((item) => (
                <ChatViewItem
                  key={item.id}
                  item={item}
                  roomAnonymously={state.roomAnonymously}
                  handlerChangeChat={handlerSelectUser}
                />
              ))}
          </div>
        </div>
      </div>
    )
  }

  const renderChatRoomSectionMobile = () => {
    return (
      <div className='chat-rooms'>
        <div className='chat-rooms-list-mobile'>
          <div className='row'>
            <div className='col-12'>
              <div>
                <div className='chat-rooms-title'>Stanze</div>
                {renderChatRoomList()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderChatRoomList = () => {
    return (
      <ul className='list-group'>
        {chatsOrder().map((chat) => (
          <ChatViewList
            key={chat.name}
            chat={chat}
            handlerChangeChat={handlerChangeChat}
          />
        ))}
      </ul>
    )
  }

  return (
    <div className='chat-container'>
      <div className='chat-section'>
        <div className='chat-body'>
          <div className='chat-body-section'>
            <Sidebar
              onlineUsers={onlineUsers()}
              chats={chatsOrder()}
              handlerRoomItemClick={handlerRoomItemClick}
              handlerUserItemClick={handlerUserItemClick}
            />
            {renderSpace()}
          </div>
        </div>
        <div className='chat-footer'>
          <form onSubmit={handlerSubmit}>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                id='chat-message'
                name='message'
                placeholder='Enter chat message'
              />
              <div className='input-group-append'>
                <button className='btn btn-outline-primary' type='submit'>
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChatView
