import React from 'react'

import { connect as reduxConnect } from 'react-redux'

import ServiceManager from '../../../service/serviceManager'

import { spinner } from '../../../actions/appActions'

import UploadFileView from '../../views/uploadFileView/uploadFileView'

export class EntityModel extends React.Component {
  state = {
    data: { ...this.props.model },
    list: []
  }

  get headers() {
    return this.props.listHeaders.map((e) => e.label)
  }

  get recordKey() {
    return this.props.listHeaders.map((e) => e.fieldName)
  }

  get upserId() {
    return `#${this.props.namespace}-upsert-modal`
  }

  get upsertTargetId() {
    return `${this.props.namespace}-upsert-modal`
  }

  get deleteId() {
    return `#${this.props.namespace}-delete-modal`
  }

  get deleteTargetId() {
    return `${this.props.namespace}-delete-modal`
  }

  async componentDidMount() {
    const { result: list } = await ServiceManager.entityList(
      this.props.collection
    )
    this.setState({ list })
  }

  handlerNew = (event) => {
    const { name, value, type } = event.target
    if (type === 'file') return

    this.setState({
      data: { ...this.state.data, [name]: value }
    })
  }

  handlerSave = async (event) => {
    event.preventDefault()

    this.props.spinner(true)

    await ServiceManager.entityUpsert(this.state.data, this.props.collection)
    const { result: list } = await ServiceManager.entityList(
      this.props.collection
    )

    this.setState({ list, data: { ...this.props.model } })
    this.props.spinner(false)
  }

  handlerSelect = async (event) => {
    this.props.spinner(true)
    await ServiceManager.entityDelete(this.state.data, this.props.collection)
    const { result: list } = await ServiceManager.entityList(
      this.props.collection
    )
    this.setState({ list, data: { ...this.props.model } })
    this.props.spinner(false)
  }

  handlerEditSelect = (item) => (e) => {
    console.log({ item })
    this.setState({ data: { id: item._id, ...item.data } })
  }

  handlerDeleteSelect = (item) => (e) => {
    console.log({ item })
    this.setState({ data: { id: item._id, ...item.data } })
  }

  handlerCleanSelect = () => {
    this.setState({ data: { ...this.props.model } })
  }

  render() {
    return (
      <div className='card mt-3'>
        <div className='card-header'>{this.props.labelTitle}</div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target={this.upserId}
              >
                {this.props.labelNew}
              </button>
            </div>
            <div className='col-12'>
              <h5 className='card-title'>{this.props.labelList}</h5>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    {this.headers.map((header) => (
                      <th key={header} scope='col'>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                {this.renerList()}
              </table>
            </div>
          </div>
          {this.renderUpsert()}
          {this.renderDelete()}
        </div>
      </div>
    )
  }

  renerList() {
    return (
      <tbody>
        {this.state.list.map((record) => (
          <tr key={record[this.props.listKey]}>
            {this.recordKey.map((col) => (
              <td>{record.data[col]}</td>
            ))}
            <td className='text-end'>
              <div className='btn-group'>
                <button
                  aria-expanded='false'
                  className='btn btn-primary dropdown-toggle'
                  data-bs-toggle='dropdown'
                  type='button'
                >
                  Actions
                </button>
                <ul className='dropdown-menu'>
                  <li>
                    <div
                      className='dropdown-item'
                      onClick={this.handlerEditSelect(record)}
                      data-bs-toggle='modal'
                      data-bs-target={this.upserId}
                    >
                      Edit
                    </div>
                  </li>
                  <li>
                    <div
                      className='dropdown-item'
                      onClick={this.handlerDeleteSelect(record)}
                      data-bs-toggle='modal'
                      data-bs-target={this.deleteId}
                    >
                      Delete
                    </div>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    )
  }

  renderUpsert() {
    return (
      <form onSubmit={this.handlerSave} onChange={this.handlerNew}>
        <div
          aria-hidden='true'
          className='modal fade'
          id={this.upsertTargetId}
          tabindex='-1'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{this.props.labelNewModal}</h5>
                <button
                  aria-label='Close'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  type='button'
                  onClick={this.handlerCleanSelect}
                ></button>
              </div>
              <div className='modal-body'>
                <div className='row'>
                  {this.props.form.map(this.renderFormItem)}
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'
                  type='button'
                  onClick={this.handlerCleanSelect}
                >
                  Close
                </button>
                <button
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  type='submit'
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  renderFormItem = (field) => {
    switch (field.type) {
      case 'textarea':
        return (
          <div className='col-12 mt-3'>
            <label className='form-label'>{field.label}</label>
            <textarea
              name={field.fieldName}
              className='form-control'
              rows='6'
              value={this.state.data[field.fieldName]}
            ></textarea>
          </div>
        )
      case 'upload':
        return (
          <div className='col-12 mt-3'>
            <label className='form-label'>{field.label}</label>
            <UploadFileView
              name={field.fieldName}
              accept={field.accept}
              onChange={this.handlerNew}
            />
          </div>
        )
      default:
        return (
          <div className='col-12' key={field.name}>
            <label className='form-label'>{field.label}</label>
            <input
              name={field.fieldName}
              type={field.type}
              className='form-control'
              value={this.state.data[field.fieldName]}
            ></input>
          </div>
        )
    }
  }

  renderDelete() {
    return (
      <form onSubmit={this.handlerSave} onChange={this.handlerNew}>
        <div
          aria-hidden='true'
          className='modal fade'
          id={this.deleteTargetId}
          tabindex='-1'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{this.props.labelDeleteModal}</h5>
                <button
                  aria-label='Close'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  type='button'
                  onClick={this.handlerCleanSelect}
                ></button>
              </div>
              <div className='modal-body'>
                {this.props.labelDeleteDisclamerModal} "{this.state.data.name}"?
              </div>
              <div className='modal-footer'>
                <button
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'
                  type='button'
                  onClick={this.handlerCleanSelect}
                >
                  Close
                </button>
                <button
                  className='btn btn-danger'
                  data-bs-dismiss='modal'
                  type='submit'
                  onClick={this.handlerSelect}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

EntityModel.defaultProps = {
  model: {
    id: null,
    name: '',
    startDate: new Date(),
    description: '',
    image: ''
  },
  namespace: 'entity-model',
  collection: 'entity',
  labelTitle: 'label-title',
  labelNew: 'label-new',
  labelList: 'label-list',
  labelNewModal: 'label-new-modal',
  labelDeleteModal: 'label-delete-modal',
  labelDeleteDisclamerModal: 'label-delete-disclaimer-modal',

  listKey: '_id',
  listHeaders: [
    {
      label: 'Name',
      fieldName: 'name'
    },
    {
      label: 'Date',
      fieldName: 'startDate'
    }
  ],

  form: [
    {
      label: 'Name',
      fieldName: 'name',
      type: 'text'
    },
    {
      label: 'Start Date',
      fieldName: 'startDate',
      type: 'date'
    },
    {
      label: 'Description',
      fieldName: 'description',
      type: 'textarea'
    },
    {
      label: 'Image',
      fieldName: 'image',
      type: 'text',
      accept: 'image/png, image/jpeg'
    }
  ]
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  spinner: (payload) => dispatch(spinner(payload))
})

export function connect(Component) {
  return reduxConnect(mapStateToProps, mapDispatchToProps)(Component)
}

export default connect(EntityModel)
