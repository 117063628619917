const initialState = {
  messages: {},
  roomId: 'lobby',
  users: {}
}
export default (state = initialState, { payload, type }) => {
  let updateState = {}
  switch (type) {
    case 'room-select':
      updateState = {
        roomId: payload
      }
      break
    case 'client-connected':
      const { socketId } = payload
      updateState = {
        users: { ...state.users, [socketId]: payload }
      }
      break
    case 'client-disconnect':
      const users = { ...state.users }
      delete users[payload.socketId]
      updateState = {
        users
      }
      break
    case 'message-add':
      const messages = { ...state.messages }
      messages[payload.roomId] ??= []
      const { user, ...body } = payload
      const message = {
        ...body,
        read: state.roomId === payload.roomId,
        myMessage: payload.roomData.facebookId === user.facebookId
      }
      messages[payload.roomId].push(message)

      updateState = {
        messages
      }
      break
    default:
      return state
  }

  return { ...state, ...updateState }
}
