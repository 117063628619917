import React from 'react'

import {connect} from "react-redux";

import ServiceManager from './../../../service/serviceManager';

class BarSpotify extends React.Component {

    async handlerManageLoginSpotify(){
		const responseUrl = await ServiceManager.createAuthorizeCallout();
		console.log(responseUrl)
		window.location.href = responseUrl.authorizeURL;
	}

    render() {
        return (
            <div className="card mt-3">
                <div className="card-header">
                    Account Spotify
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="card-text">Collega il tuo Account Spotify al Bar</p>
                        </div>
                        <div className="col-12 mt-3 text-center">
                            <div className="btn btn-primary" onClick={this.handlerManageLoginSpotify}>
                                <i className="bi bi-spotify"></i>
                                Connect Spotify
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps)(BarSpotify);