import { EntityModel, connect } from './../entityModel/entityModel'

class BarMenu extends EntityModel {}

BarMenu.defaultProps = {
  model: {
    id: null,
    name: '',
    price: '',
    description: '',
    image: ''
  },
  namespace: 'menu',
  collection: 'menus',
  labelTitle: 'Menu',
  labelNew: 'New Item +',
  labelList: 'Menu Items',
  labelNewModal: 'New Item',
  labelDeleteModal: 'Delete Item',
  labelDeleteDisclamerModal: 'Delete Item',

  listKey: '_id',
  listHeaders: [
    {
      label: 'Name',
      fieldName: 'name'
    },
    {
      label: 'Price',
      fieldName: 'price'
    },
    {
      label: 'Description',
      fieldName: 'description'
    }
  ],

  form: [
    {
      label: 'Name',
      fieldName: 'name',
      type: 'text'
    },
    {
      label: 'Price',
      fieldName: 'price',
      type: 'number'
    },
    {
      label: 'Description',
      fieldName: 'description',
      type: 'textarea'
    },
    {
      label: 'Image',
      fieldName: 'image',
      type: 'upload',
      accept: 'image/png, image/jpeg'
    }
  ]
}

export default connect(BarMenu)
