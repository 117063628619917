export default class ServiceManager{

	static get domain(){
		return process.env.REACT_APP_HOST_API || '';
	}

	static async createAuthorizeCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/create-authorize-url-spotify`);
	}

	static async createAuthorizeFaceookCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/create-authorize-url-facebook`);
	}

	static async playCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/play`);
	}

	static async statusCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/spotify/get-my-current-playback-state`);
	}

	static async searchCallout(query){
		return await ServiceManager.callout(`${ServiceManager.domain}/spotify/search?query=${query}`);
	}

	static async addToQueueCallout(uri, id){
		return await ServiceManager.callout(`${ServiceManager.domain}/spotify/add-to-queue?uri=${uri}&id=${id}`);
	}

	static async getQueueCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/spotify/queue`);
	}

	static async facebookMeCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/facebook/me`);
	}

	static async facebookMePictureCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/facebook/me/picture`);
	}

	static async roomDetailCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/room-detail`);
	}

	static async roomUpdateCallout(data){
		return await ServiceManager.callout(`${ServiceManager.domain}/room-update`, data, {method: 'POST'});
	}

	static async logoutCallout(){
		return await ServiceManager.callout(`${ServiceManager.domain}/logout`);
	}

	static async eventList(){
		return await ServiceManager.callout(`${ServiceManager.domain}/event-list`);
	}

	static async eventUpsert(data){
		return await ServiceManager.callout(`${ServiceManager.domain}/event-upsert`, data, {method: 'POST'});
	}

	static async eventDelete(data){
		return await ServiceManager.callout(`${ServiceManager.domain}/event-delete`, data, {method: 'POST'});
	}

	static async entityList(collection){
		return await ServiceManager.callout(`${ServiceManager.domain}/entity-list?collection=${collection}`);
	}

	static async entityUpsert(data, collection){
		return await ServiceManager.callout(`${ServiceManager.domain}/entity-upsert`, {data, collection}, {method: 'POST'});
	}

	static async entityDelete(data, collection){
		return await ServiceManager.callout(`${ServiceManager.domain}/entity-delete`, {data, collection}, {method: 'POST'});
	}

	static async uploadFile(formData){
		return await ServiceManager.uploadCallout(`${ServiceManager.domain}/upload-file`, formData);
	}

	static async callout(url, data, options = {}){
		const request = {credentials: "include", headers: {
			'Content-Type': 'application/json'
		}, ...options, body: data ? JSON.stringify(data) : null}
		const response = await fetch(url, request);
		const jsonData = await response.json(); // parses JSON

		if(jsonData.error) throw new Error(jsonData.error)

		return jsonData;
	}

	static async uploadCallout(url, body){
		const response = await fetch(url, {credentials: "include", method: 'POST', body});
		const jsonData = await response.json(); // parses JSON

		if(jsonData.error) throw new Error(jsonData.error)

		return jsonData;
	}

}