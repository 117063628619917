import React from 'react'

import { connect } from 'react-redux'

import { setContext } from '../../../actions/appActions'
import ServiceManager from '../../../service/serviceManager'

import './eventsView.css'

class EventsView extends React.Component {
  state = {
    list: []
  }

  async componentDidMount() {
    const { result: list } = await ServiceManager.eventList()
    this.setState({ list })
  }

  render() {
    return (
      <div className='event-view'>
        <div className='card mt-3'>
          <div className='card-header'>Events</div>
          <div className='card-body'>
            <div className='row justify-content-center'>
              {this.state.list.map(this.renderItem)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderItem = (item) => {
    return (
      <div className='col-12 col-md-6 col-lg-3 mt-3' key={item._id}>
        <div className='card h-100 card-shadow'>
          <img
            src={item.data.image}
            className='card-img-top'
            alt={item.data.name}
          />
          <div className='card-body'>
            <h5 className='card-title'>{item.data.name}</h5>
            <p className='card-text'>{item.data.description}</p>
          </div>
          <div className='card-footer'>
            <small className='text-muted'>
              <strong>Date: </strong> {item.data.startDate}
            </small>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  setContext: (payload) => dispatch(setContext(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventsView)
