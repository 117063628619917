import React from 'react'

import { connect } from 'react-redux'

import { setProfile, viewChange } from '../../../actions/appActions'
import ServiceManager from '../../../service/serviceManager'

import './loginView.css'

class LoginView extends React.Component {
  state = {
    spinner: true
  }

  async componentDidMount() {
    const result = await ServiceManager.roomDetailCallout()
    console.log({ result })

    if (result.facebookId) {
      this.props.setProfile(result)
      this.props.viewChange('home')
    }

    this.setState({ spinner: false })
  }

  async handlerManageLoginFacebook() {
    const responseUrl = await ServiceManager.createAuthorizeFaceookCallout()
    console.log(responseUrl)
    window.location.href = responseUrl.authorizeURL
  }

  render() {
    if (this.state.spinner) return this.renderSpinner()
    return (
      <div className='overload-contain overload-on-top'>
        <div className='overload-contain-child'>
          <div className='overload-element text-center'>
            <div className='login-title'>
              Diapido <i className='bi bi-balloon-heart-fill'></i>
            </div>
            <div className='login-disclaimer'>
              Vuoi essere la star della serata collegati al sito scegli la
              canzone e conosci nuova gente
            </div>
            <div
              className='login-button btn btn-primary'
              onClick={this.handlerManageLoginFacebook}
            >
              <i className='bi bi-facebook'></i> Login con Facebook
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSpinner() {
    return (
      <div className='overload-contain overload-on-top'>
        <div className='overload-contain-child'>
          <div className='overload-element text-center'>
            <div className='spinner-border' role='status' />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  viewChange: (payload) => dispatch(viewChange(payload)),
  setProfile: (payload) => dispatch(setProfile(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
