import React from 'react'

import { connect } from 'react-redux'

import { setProfile, spinner } from './../../../actions/appActions'

import ServiceManager from './../../../service/serviceManager'

import UploadFileView from '../../views/uploadFileView/uploadFileView'

const barModel = {
  name: '',
  disclaimer: '',
  activate: false
}

class BarSettings extends React.Component {
  state = {
    barSettings: this.props.appReducer?.user?.bar?.barData || barModel
  }

  handlerChange = (event) => {
    const { name, value, checked, type } = event.target
    const { barSettings } = this.state

    this.setState({
      barSettings: {
        ...barSettings,
        [name]: type === 'checkbox' ? checked : value
      }
    })
  }

  handlerSave = async (event) => {
    event.preventDefault()

    this.props.spinner(true)
    const resultUpdate = await ServiceManager.roomUpdateCallout(
      this.state.barSettings
    )
    console.log({ resultUpdate })

    const result = await ServiceManager.roomDetailCallout()
    this.props.setProfile(result)
    this.props.spinner(false)
  }

  render() {
    return (
      <div className='card mt-3'>
        <div className='card-header'>Bar Settings</div>
        <div className='card-body'>
          <form onSubmit={this.handlerSave} onChange={this.handlerChange}>
            <div className='row'>
              <div className='col-12'>
                <p className='card-text'>Gestisci le impostazioni del Bar</p>
              </div>
              <div className='col-12 mt-3'>
                <div className='mb-3'>
                  <label for='bar-name' className='form-label'>
                    Name
                  </label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    id='bar-name'
                    placeholder='Nome Bar'
                    value={this.state.barSettings.name}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-3'>
                  <label for='bar-disclaimer' className='form-label'>
                    Disclaimer
                  </label>
                  <input
                    type='text'
                    name='disclaimer'
                    className='form-control'
                    id='bar-disclaimer'
                    placeholder='Disclaimer Bar'
                    value={this.state.barSettings.disclaimer}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-3'>
                  <label className='form-label'>Image</label>
                  <UploadFileView
                    name='image'
                    accept='image/png, image/jpeg'
                    onChange={this.handlerChange}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    name='activate'
                    type='checkbox'
                    role='switch'
                    id='bar-activate'
                    checked={this.state.barSettings.activate}
                  />
                  <label className='form-check-label' for='bar-activate'>
                    Activate Bar
                  </label>
                </div>
              </div>
              <div className='col-12 text-end'>
                <button type='submit' className='btn btn-primary'>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  spinner: (payload) => dispatch(spinner(payload)),
  setProfile: (payload) => dispatch(setProfile(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(BarSettings)
