import ServiceManager from '../../../service/serviceManager'

import { EntityModel, connect } from '../entityModel/entityModel'

class BarEvents extends EntityModel {}

BarEvents.defaultProps = {
  model: {
    id: null,
    name: '',
    startDate: new Date(),
    description: '',
    image: ''
  },
  namespace: 'events',
  collection: 'events',
  labelTitle: 'Events',
  labelNew: 'New Event +',
  labelList: 'Events List',
  labelNewModal: 'New Event',
  labelDeleteModal: 'Delete Event',
  labelDeleteDisclamerModal: 'Delete event',

  ServiceManager: {
    eventList: ServiceManager.eventList,
    eventUpsert: ServiceManager.eventUpsert,
    eventDelete: ServiceManager.eventDelete
  },

  listKey: '_id',
  listHeaders: [
    {
      label: 'Name',
      fieldName: 'name'
    },
    {
      label: 'Date',
      fieldName: 'startDate'
    },
    {
      label: 'Description',
      fieldName: 'description'
    }
  ],

  form: [
    {
      label: 'Name',
      fieldName: 'name',
      type: 'text'
    },
    {
      label: 'Start Date',
      fieldName: 'startDate',
      type: 'date'
    },
    {
      label: 'Description',
      fieldName: 'description',
      type: 'textarea'
    },
    {
      label: 'Image',
      fieldName: 'image',
      type: 'upload',
      accept: 'image/png, image/jpeg'
    }
  ]
}

export default connect(BarEvents)
