import PropTypes from 'prop-types'
import React from 'react'

const ChatList = ({
  items,
  handlerItemClick,
  showImg = false,
  labelActive,
  img,
  label,
  showStatus = false,
  labelKey
}) => {
  return (
    <ul className='list-group'>
      {items.map((item) => (
        <li
          className={`list-group-item chat-online-user-item ${
            item[labelActive] ? 'active' : ''
          }`}
          key={item[labelKey ?? 'id']}
          onClick={() => (handlerItemClick ? handlerItemClick(item) : null)}
        >
          {showImg && (
            <img
              className='chat-online-user-image'
              src={img.src(item)}
              alt={img.alt(item)}
            />
          )}
          <span className='mx-2'>{label(item)}</span>
          {showStatus && <i className='bi bi-circle-fill text-success'></i>}
        </li>
      ))}
    </ul>
  )
}

ChatList.propTypes = {
  img: PropTypes.object,
  labelKey: PropTypes.string,
  labelActive: PropTypes.string,
  items: PropTypes.array,
  handlerItemClick: PropTypes.func,
  label: PropTypes.func,
  showImg: PropTypes.bool,
  showStatus: PropTypes.bool
}

export default ChatList
