import { spinner } from 'actions/appActions'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import { useEffect, useState } from 'react'
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Modal,
  Row
} from 'react-bootstrap'
import { API } from 'service/polls.api'
import { userAttributes } from 'utils/model.util'
import { getRelativeTime } from 'utils/time.util'

import './PollsView.css'

const ModalVote = ({ handleVote, pollId, show, setShowModal, user }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const dispatch = useAppDispatch()

  const [poll, setPoll] = useState(null)
  const { findById } = API()

  useEffect(() => {
    if (!show) return
    dispatch(spinner(true))
    findById(pollId)
      .then((res) => {
        setPoll(res)
      })
      .finally(() => {
        dispatch(spinner(false))
      })
  }, [show])

  useEffect(() => {
    if (!user?.userVotes) return
    setSelectedOption(user.userVotes[user._id])
  }, [])

  const handleModalVote = () => {
    const pollClone = window.structuredClone
      ? structuredClone(poll)
      : JSON.parse(JSON.stringify(poll))

    if (pollClone?.userVotes?.[user._id] !== undefined) {
      const pollIndex = pollClone.userVotes[user._id]
      --pollClone.votes[pollIndex]
    }

    const votes = [...pollClone.votes]
    votes[selectedOption] = ++votes[selectedOption]
    pollClone.votes = votes
    pollClone.userVotes = {
      ...pollClone.userVotes,
      [user._id]: selectedOption
    }
    handleVote(pollClone)
  }

  const handleSelectedOption = (optionIndex) => {
    setSelectedOption(optionIndex)
  }

  if (!poll) return null
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      backdrop='static'
      keyboard={false}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Vote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{poll.title}</h4>
        <p>{poll.description}</p>
        <Card.Img variant='top' src={poll.image} className='img mb-3' />
        <ListGroup as='ol' numbered>
          {poll.options.map((option, index) => (
            <ListGroup.Item
              as='li'
              className='d-flex justify-content-between align-items-start text-wrap cursor-pointer'
              active={selectedOption === index}
              onClick={() => handleSelectedOption(index)}
              key={index}
            >
              <div className='ms-2 me-auto'>{option}</div>
              <Badge
                pill
                bg={selectedOption === index ? 'light' : 'primary'}
                text={selectedOption === index ? 'dark' : 'light'}
              >
                {poll.votes[index] === 0 ? null : poll.votes[index]}
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button variant='outline-primary' onClick={handleModalVote}>
          Vote
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const PollCard = ({ poll, user }) => {
  const defaultUrl =
    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22286%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20286%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18a7029414a%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A14pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18a7029414a%22%3E%3Crect%20width%3D%22286%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22107.19140625%22%20y%3D%2296.3%22%3E286x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E'

  const [showModalVote, setShowModalVote] = useState(false)
  const { title, description, image, date } = poll
  const dispatch = useAppDispatch()
  const { update, findById } = API()

  const handleShowModalVote = () => {
    setShowModalVote(!showModalVote)
  }

  const handleVote = async ({ _id, votes, userVotes }) => {
    dispatch(spinner(true))
    await update(_id, { votes, userVotes })
    handleShowModalVote()
    dispatch(spinner(false))
  }

  return (
    <>
      <Card style={{ width: '18rem' }}>
        <Card.Img
          variant='top'
          src={image ?? defaultUrl}
          className='card-img'
        />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
        <ListGroup className='list-group-flush'>
          <ListGroup.Item className='text-center'>
            <Badge bg='light' text='dark'>
              {getRelativeTime(new Date(date))}
            </Badge>
          </ListGroup.Item>
        </ListGroup>
        <Card.Body
          className='d-flex justify-content-center '
          style={{ padding: '0.5rem', paddingBottom: '0.5rem' }}
        >
          <Button
            variant='outline-primary '
            onClick={handleShowModalVote}
            size='md'
            className='w-100'
          >
            Vote
          </Button>
        </Card.Body>
      </Card>
      <ModalVote
        handleVote={handleVote}
        pollId={poll._id}
        user={user}
        show={showModalVote}
        setShowModal={setShowModalVote}
      />
    </>
  )
}

const PollsView = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.appReducer.user)
  const { barId } = userAttributes.apply(user)

  const [polls, setPolls] = useState([])

  const { findAll } = API()
  useEffect(() => {
    getAll()
  }, [])

  const getAll = () => {
    dispatch(spinner(true))
    findAll(barId)
      .then((polls) => {
        setPolls(polls.sort((a, b) => new Date(b.date) - new Date(a.date)))
      })
      .finally(() => {
        dispatch(spinner(false))
      })
  }

  return (
    <Container>
      <Row>
        {polls.map((poll) => (
          <Col key={poll._id} xs md={6} lg={3} className='mt-3'>
            <PollCard poll={poll} user={user?.user}></PollCard>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default PollsView
